import PropTypes from 'prop-types';

// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Toolbar, Typography } from '@mui/material';

// components

//
import './Footer.css';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from 'react-redux-multilingual';
import { Element } from 'react-scroll';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 84;
const APPBAR_DESKTOP = 230;

const RootStyle = styled('footer')(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  // WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.paper, 1),
  width: '100%',
  background:
    'linear-gradient(0deg, rgba(255,255,255,0.2987570028011205) 0%, rgb(13 126 55 / 76%) 50%, rgba(255,255,255,0.3) 100%)'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('xs')]: {
    alignItems: 'start',
    minHeight: APPBAR_MOBILE,
    padding: theme.spacing(0, 3)
  },
  [theme.breakpoints.up('md')]: {
    minHeight: APPBAR_DESKTOP,
    alignItems: 'center',
    padding: theme.spacing(0, 4)
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    alignItems: 'center',
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

Footer.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function Footer() {
  const translate = useTranslate();
  const navigate = useNavigate();
  return (
    <RootStyle>
      <div className="visit-card-footer-bg">
        <Element name="contact" />
        <ToolbarStyle>
          <div
            className="footer-logo"
            onClick={() => {
              navigate(`/`);
            }}
          ></div>
          <div className="footer-address-container">
            <Box>
              <Typography className="footer-address">
                {translate('visitCard_contact_address')}
              </Typography>
              <a
                target="_blank"
                className="footer-address-text"
                href="https://goo.gl/maps/PnRQBiFtKMh7gci86"
                rel="noreferrer"
              >
                {translate('visitCard_contact_address_text')}
              </a>
            </Box>
            <Box>
              <Typography className="footer-address">
                {translate('visitCard_contact_contacts')}
              </Typography>
              <a
                className="footer-address-text"
                href="mailto:info@bandbsolutions.com.ua"
                rel="noreferrer"
              >
                info@bandbsolutions.com.ua
              </a>
            </Box>
          </div>
        </ToolbarStyle>
      </div>
    </RootStyle>
  );
}
